import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Entity, EntityNameType } from '@proman/services/entity.service';
import { OcrEntityInterface } from '@proman/resources/ocr';
import { PromanFile } from '@proman/interfaces/entity-interfaces';
import { ImagePathService } from '@proman/services/image-path.service';
import { SelectOption } from '@proman/interfaces/object-interfaces';
import { FilterService } from '@proman/services/filter.service';
import moment from 'moment';
import { findByProperty, mapOptionTranslate, roundNumber } from '@proman/utils';
import { PurchaseEntityInterface, PurchaseTypes } from '@proman/resources/purchase';
import { PromanStateService } from '@frontend/shared/services/proman-state.service';
import { Store } from '@ngrx/store';

@Component({
    selector: 'pm-ocr-image-read-dialog',
    template: `
        <pro-dialog-title title="ocr"></pro-dialog-title>
        <div mat-dialog-content fxLayout="column">
            <div fxLayout="row">
                <pm-upload *ngIf="!file || true"
                           [file]="file"
                           (onUpload)="loadFile($event)"></pm-upload>
            </div>
            <ng-container *ngIf="file">
                <pro-select [value]="purchaseType"
                            [config]="{ label: 'purchase_type', key: 'id' }"
                            [options]="purchaseTypeOptions"
                            (onChange)="purchaseType = $event"
                ></pro-select>

<!--                <pro-select [value]="field"-->
<!--                        [config]="{ label: 'field', key: 'id', isNone: true } "-->
<!--                        [options]="fieldOptions"-->
<!--                        (onChange)="setField($event)"-->
<!--                ></pro-select>-->

                <div fxLayout="column">
                    <div fxFlex *ngFor="let item of values; let $index = index;">
                      <ng-container [ngSwitch]="item.type">
                        <div *ngSwitchCase="'string'" fxLayout="row" fxLayoutAlign="start center">
                          <mat-radio-button [checked]="field === item.key" (change)="setField(item.key)"></mat-radio-button>
                          <pm-txt [value]="item.value"
                                  [config]="{ label: item.name }"
                                  (onChange)="setValue(item, $event)"
                          ></pm-txt>
                        </div>
                        <div *ngSwitchCase="'date'" fxLayout="row" fxLayoutAlign="start center">
                          <mat-radio-button [checked]="field === item.key" (change)="setField(item.key)"></mat-radio-button>
                          <pro-datepicker [value]="item.value" fxFlex
                                          [config]="{ label: item.name, hideTime: true }"
                                          (onChange)="setValue(item, $event)"
                          ></pro-datepicker>
                        </div>
                        <div *ngSwitchCase="'entity'" fxLayout="row" fxLayoutAlign="start center">
                          <mat-radio-button [checked]="field === item.key" (change)="setField(item.key)"></mat-radio-button>
                          <pro-autoc *ngIf="item.type === 'entity'"
                                    [value]="item.value" fxFlex
                                    [config]="{ label: item.name, entity: fields[$index].entity, searchFields: fields[$index].searchFields }"
                                    (onChange)="setValue(item, $event)"
                          ></pro-autoc>
                        </div>
                      </ng-container>
                    </div>
                </div>
                <div class="ImageContainer"><img *ngIf="fileSrc" [src]="fileSrc" #img />
                    <div class="ImageOverlay"
                         draggable="false"
                         (mousedown)="handleMouseDown($event)"
                         (mouseup)="handleMouseUp($event)"
                         (mousemove)="handleMouseMove($event)">
                        <div #marker style="display: none;" class="ImageMarker"></div>
                    </div>
                    <div *ngFor="let scanned of scannedPositions"
                        [ngStyle]="{
                            width: scanned.width + 'px',
                            height: scanned.height + 'px',
                            left: scanned.x + 'px',
                            top: scanned.y + 'px'
                         }"
                         class="ScannedPart"
                    >
                    </div>
                </div>
            </ng-container>

        </div>
        <pro-dialog-actions (callback)="confirm()" variant="confirm">
            <pro-btn pmPadding
                    [label]="'save'"
                    (onClick)="save()"
                    [disabled]="saved"></pro-btn>
        </pro-dialog-actions>
    `,
    styles: [`
        img {
            max-width: 100%;
        }

        .ImageContainer {
            position: relative;
        }

        .ImageOverlay {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            outline: 2px solid #2196F3;
        }

        .ImageMarker {
            position: absolute;
            pointer-events: none;
        }

        .ScannedPart {
            position: absolute;
            background: rgba(93, 255, 190, 0.35);
        }
    `]
})

export class OcrImageReadDialogComponent implements OnInit {
    @ViewChild('marker') marker: ElementRef;
    @ViewChild('img', { static: true }) img: ElementRef;
    ocrEntity: OcrEntityInterface;
    purchaseEntityInterface: PurchaseEntityInterface;
    ocrEntityInterface: OcrEntityInterface;
    file: PromanFile;
    fileSrc: string;
    isMark: boolean = false;
    startPos: number[];
    endPos: number[];
    size: number[];
    fields: any[];
    fieldOptions: SelectOption[];
    field: string;
    values: Array<{ key: string; type: string; value: any; name: string; alias?: string; entity?: EntityNameType; }> = [];
    ratio: number;
    templates: any = [];
    scannedPositions: Array<{ field: string; x: number; y: number; width: number; height: number }> = [];
    purchaseType: string = 'materials';
    saved: boolean = false;
    purchaseTypeOptions: SelectOption[];

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
        private Entity: Entity,
        private ImagePath: ImagePathService,
        private Filter: FilterService,
        private PromanState: PromanStateService,
        private store: Store,
        private dialogRef: MatLegacyDialogRef<OcrImageReadDialogComponent>
    ) {
        this.ocrEntity = this.Entity.get('ocr') as OcrEntityInterface;
        this.purchaseEntityInterface = this.Entity.get('purchase');
        this.purchaseEntityInterface.getPurchaseTypes().then((response) => this.purchaseTypeOptions = response.map((key: any) => mapOptionTranslate(key, this.Filter.translate)));
        this.ocrEntityInterface = this.Entity.get('ocr');
    }

    ngOnInit() {
        this.field = 'supplier';
        this.fields = [
            {
                name: 'supplier',
                key: 'supplier',
                type: 'entity',
                entity: 'supplier',
                searchFields: ['name']
            },
            {
                name: 'invoice_number',
                type: 'string',
                key: 'invoiceNumber'
            },
            {
                name: 'invoice_date',
                type: 'date',
                key: 'date'
            },
             {
                name: 'vat',
                key: 'vat',
                type: 'entity',
                entity: 'vat',
                searchFields: ['name', 'percentage']
            },
             {
                name: 'type',
                key: 'type',
                type: 'select'
            },
             {
                name: 'comments',
                type: 'string',
                key: 'comments'
            }
        ];
        this.fieldOptions = this.fields.map((item) => ({ id: item.key, name: this.Filter.translate(item.name) }  as unknown as SelectOption));
        this.values = this.fields;

    }

    loadFile(files: PromanFile[]) {
        this.file = files[0];
        this.fileSrc = this.ImagePath.getFile(this.file, 'ocr');

        const img = document.createElement('img');
        img.onload = (event) => {
            const container = document.querySelector('.ImageOverlay');
            console.log('container', container);
            console.log('container.clientWidth', container.clientWidth);
            console.log('img.naturalWidth', img.naturalWidth);
            this.ratio = container.clientWidth / img.naturalWidth;
            console.log('this.ratio', this.ratio);

        };
        img.src = this.fileSrc;

        img.remove();

        this.ocrEntityInterface.recognizeByTemplate({ newId: this.file.newId })
            .then((response: any) => {
                if (response?.data?.id) {
                    this.dialogRef.close();
                    this.PromanState.to('Purchase', { purchaseId: response?.data?.id });
                }
            });

    }

    handleMouseDown(event: MouseEvent) {
        this.isMark = true;
        this.startPos = [event.offsetX, event.offsetY];
        console.log('event', event);
    }

    handleMouseUp(event: MouseEvent) {
        if (this.isMark) {
            this.endPos = [event.offsetX, event.offsetY];
            this.size = [this.endPos[0] - this.startPos[0], this.endPos[1] - this.startPos[1]];

            this.templates[this.field] = {
                field: this.field,
                x: this.getRatio(this.startPos[0]),
                y: this.getRatio(this.startPos[1]),
                width: this.getRatio(this.size[0]),
                height: this.getRatio(this.size[1])
            };

            this.scannedPositions = [];
            for (const key in this.templates) {
                this.scannedPositions.push({
                    field: this.templates[key].field,
                    x: this.startPos[0],
                    y: this.startPos[1],
                    width: this.size[0],
                    height: this.size[1]
                });
            }

            this.ocrEntity
                .recognize({
                    newId: this.file.newId,
                    type: this.field,
                    x: this.getRatio(this.startPos[0]),
                    y: this.getRatio(this.startPos[1]),
                    width: this.getRatio(this.size[0]),
                    height: this.getRatio(this.size[1])
                })
                .then((response: string) => {
                    if (response && this.field) {
                        const field = findByProperty(this.fields, 'key', this.field);
                        const type = field.type;

                        // const currentValueIndex = getIndexByProperty(this.values, 'key', this.field);
                        // if (isDefined(currentValueIndex)) {
                        //     this.values.splice(currentValueIndex, 1);
                        // }

                        if (type === 'string') {
                          Object.assign(this.values.find((value) => value.key === field.key), { value: response });
                          console.log(this.values);
                            // this.values.push({ key: field.key, name: field.name, value: response, type });
                        }

                        if (type === 'date') {
                            const date = moment(new Date(response));
                            if (date.isValid()) {
                              Object.assign(this.values.find((value) => value.key === field.key), { value: date.format() });
                                // this.values.push({ key: field.key, name: field.name, value: date.format(), type });
                            }
                        }

                        if (type === 'select') {
                            const date = moment(new Date(response));

                            // this.values.push({ key: field.key, name: field.name, value: null, type });
                            Object.assign(this.values.find((value) => value.key === field.key), { value: null });


                        }

                        if (type === 'entity') {
                            const request = { search: {} };
                            field.searchFields.forEach((searchField: string) => request.search[searchField] = response);
                            this.Entity.get(field.entity as EntityNameType)
                                .search(request)
                                .then((responseOptions: any[]) => {
                                    if (responseOptions.length) {
                                      Object.assign(this.values.find((value) => value.key === field.key), { value: responseOptions[0], alias: response });
                                        // this.values.push({
                                        //     key: field.key,
                                        //     name: field.name,
                                        //     value: responseOptions[0],
                                        //     alias: response,
                                        //     entity: field.entity,
                                        //     type
                                        // });
                                    }
                                });
                        }

                    }

                })
                .catch(() => {});

        }
        this.isMark = false;
    }

    handleMouseMove(event: MouseEvent) {
        if (this.isMark) {
            this.endPos = [event.offsetX, event.offsetY];
            this.size = [this.endPos[0] - this.startPos[0], this.endPos[1] - this.startPos[1]];
            this.marker.nativeElement.style.display = 'block';
            this.marker.nativeElement.style.background = '#2196F333';
            this.marker.nativeElement.style.left = this.startPos[0] + 'px';
            this.marker.nativeElement.style.top = this.startPos[1] + 'px';
            this.marker.nativeElement.style.width = this.size[0] + 'px';
            this.marker.nativeElement.style.height = this.size[1] + 'px'

        } else {
            this.marker.nativeElement.style.display = 'none';
        }
    }

    getRatio(value: number): number {
        return roundNumber(value / this.ratio, 0);
    }

    setField(field: string) {
        this.field = field;
    }

    setValue(valueObject: any, value: any) {
        valueObject.value = value;
    }

    save() {
        let supplier = this.values.find((value: any) => value.key === 'supplier');
        if (!supplier) return;
        this.templates.supplierId = supplier.value?.id;
        this.templates.supplierAlias = supplier.alias;
        this.templates.type = this.purchaseType;
        this.templates.newId = this.file.newId;
        this.ocrEntityInterface.saveTemplate(this.templates);
        this.saved = true;
    }

    confirm() {
        this.purchaseEntityInterface
            .create({ type: this.purchaseType as PurchaseTypes, supplier: [] as unknown as number })
            .then((response: { data: number }) => {
                const id = response.data;
                const request: { [key: string]: any } = {};
                this.values.forEach((value) => request[value.key] = value.value?.id || value.value);
                request['type'] ??= this.purchaseType;
                this.purchaseEntityInterface.update(Object.assign(request, { id }))
                    .then(() => {
                        this.purchaseEntityInterface.addAssociation({ id, invoiceFile: [this.file.id] })
                            .then(() => {
                                this.dialogRef.close();
                                this.PromanState.to('Purchase', { purchaseId: id });
                            })
                })
        });
    }

}

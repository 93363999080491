import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Material, Purchase, MaterialType, Supplier } from '@proman/interfaces/entity-interfaces';
import { PurchaseEntityInterface, PurchaseTypes } from '@proman/resources/purchase';
import { Entity } from '@proman/services/entity.service';
import { Dialog } from '@frontend/shared/services/dialog.service';
import { EntityRemoveDialogComponent } from '@frontend/shared/components/entity-remove-dialog.component';
import { PromanStateService } from '@frontend/shared/services/proman-state.service';
import { ToastService } from '@proman/services/toast.service';

@Injectable({
    providedIn: 'root'
})
export class MaterialsService {
    currentMaterial: BehaviorSubject<Material> = new BehaviorSubject<Material>({} as Material);
    purchaseEntityInterface: PurchaseEntityInterface;

    constructor(
        private Dialog: Dialog,
        private Entity: Entity,
        private PromanState: PromanStateService,
        private Toast: ToastService,
    ) {
        this.purchaseEntityInterface = this.Entity.get('purchase');
    }

    createPurchase(
        type: PurchaseTypes,
        options: {
            parent?: Purchase;
            supplier?: Supplier;
            suppliersQuery?: { [key: string]: unknown };
        } = {}
    ): Promise<number> {

       return new Promise((resolve) => {
           const createPurchaseCallback = (supplierId: number) => {
               this.purchaseEntityInterface.create(
                   Object.assign(
                       { type },
                       options.parent ? { parent: options.parent.id } : {},
                       supplierId ? { supplier: supplierId } : {},
                   )
                    )
                   .then((response) => {
                       resolve(response.data);
                   });
           };

           createPurchaseCallback(options?.supplier?.id);
       });
    }

    removeMaterialType = (type: MaterialType) => {
        return new Promise((resolve) => {
            const removeCallback = () => {
                this.Dialog.open(EntityRemoveDialogComponent, { item: type, entity: 'material_type' })
                    .then(() => {
                        resolve(true);
                        this.PromanState.to(type.stock === 'inventory' ? 'InventoryTypes' : 'MaterialTypes');
                    });
            };

            (this.Entity.get('material')
                .search({ 'materialType.id': type.id, 'count': true }) as unknown as Promise<{ count: number }>)
                .then((response) => {
                    if (response.count) {
                        this.Toast.pop('info', 'material_type_already_used_in_material');
                        resolve(null);
                    } else {
                        removeCallback();
                    }
                });
        });

    };

}
